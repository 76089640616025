@keyframes stampIn {
    0% {
      transform: scale(0.5); /* Start with a smaller scale */
      opacity: 0; /* Initially invisible */
    }
    50% {
      transform: scale(1.1); /* Slightly zoom in to create a "stamping" effect */
      opacity: 1; /* Fully visible */
    }
    100% {
      transform: scale(1); /* End at its normal size */
      opacity: 1; /* Fully visible */
    }
  }
  
  .card-slide-in-right {
    animation: stampIn 1s ease-out forwards; /* Animation duration and easing */
    opacity: 0; /* Ensures the card starts invisible */
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%); /* Start outside the right side of the screen */
      opacity: 0; /* Initially invisible */
    }
    100% {
      transform: translateX(0); /* End at its normal position */
      opacity: 1; /* Fully visible */
    }
  }
  
  .card-slide-in-left {
    animation: slideInLeft 1s ease-out forwards; /* Animation duration and easing */
    opacity: 0; /* Ensures the card starts invisible */
  }
  @keyframes shimmer {
    0% { background-position: 0% 50%; }
    100% { background-position: 50% 100%; }
}
