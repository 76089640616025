
.text-effects {
    display: inline-block;
    transition: transform 0.5s ease, color 3s ease;
    color: #000; /* Adjust this to your default text color */
  }
  .box-effects {
    display: inline-block;
    transition: transform 0.5s ease, color 3s ease;
    color: #000; /* Adjust this to your default text color */
  }
  
  .text-effects:hover {
    transform: translateY(-5px); /* Moves the text up by 5px */
    color: #ff5722; /* Optional: Change text color on hover */
  }
  .box-effects:hover {
    transform: translateY(-5px); /* Moves the text up by 5px */
    color: #ff5722; /* Optional: Change text color on hover */
  }