.accordion-button:hover{
    background: #E2E6EA !important;
}
.accordion-header.active {
    background: #E2E6EA !important;
}

.dropdown-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Smooth opening transition */
  }
  
  .dropdown-content.open {
    max-height: 200px; /* Adjust based on the content size */
  }