.scrollToTop {
    /* background: #ff3008; */
    /* background: -moz-linear-gradient(-45deg, #ff3008 1%, #fe792b 100%); */
    /* background: -webkit-linear-gradient(-45deg, #ff3008 1%, #fe792b 100%); */
    /* background: linear-gradient(135deg, #ff3008 1%, #fe792b 100%); */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3008', endColorstr='#fe792b', GradientType=1); */
    position: sticky;
    top: 0;
    z-index: 1;
    transform: translateY(85px);
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* margin-bottom: 16px; */
    margin-bottom: 85px;
}
